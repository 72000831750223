@import 'select2/dist/css/select2.min.css';
select.default-border ~ .select2:not(.select2-container--open) .select2-selection {
  border-color: #d4d4d4 !important;
}

.select2-container {
  display: grid;
  height: inherit !important;
  box-sizing: border-box;
  background-color: #ffffff !important;
  border-radius: 7px;
  font-weight: normal;
  font-size: calc(13px + var(--big-fs));
  line-height: 18px;
}
.select2-container .select2-selection {
  display: flex !important;
  align-items: center;
  border: 1px solid #ffffff !important;
  border-radius: 7px !important;
}
.select2-container .select2-selection.default-value-changes * {
  color: #d61f79 !important;
}
.select2-container .select2-selection.default-value-changes .select2-selection__arrow:after {
  color: #d61f79 !important;
}
.select2-container .select2-selection--single {
  width: 100%;
  justify-content: space-between;
  padding: 7px 8px 7px 15px;
  min-height: 34px;
  height: inherit !important;
}
.select2-container .select2-selection__rendered {
  line-height: 14px !important;
  padding-left: 0 !important;
  padding-right: 10px !important;
}
.select2-container .select2-selection__arrow {
  width: 12px !important;
  height: 12px !important;
  font-size: 12px !important;
  display: flex;
  align-items: center;
  position: relative !important;
  right: 0 !important;
  top: 0 !important;
  color: #949496 !important;
}
.select2-container .select2-selection__arrow:after {
  content: "";
  color: #000000 !important;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: currentColor;
  mask-size: contain;
  user-select: none;
  -webkit-mask-size: contain !important;
  -webkit-mask: url("/imgr/icons/chevron-down.svg") no-repeat center;
  mask: url("/imgr/icons/chevron-down.svg") no-repeat center;
}
.select2-container .select2-selection__arrow b {
  display: none;
}
.select2-container .sc-selection-label {
  text-transform: capitalize;
}
.select2-container .select2-dropdown {
  background: #ffffff;
  overflow: hidden;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}
.select2-container .select2-dropdown--above {
  top: -2px;
}
.select2-container .select2-dropdown--below {
  margin-top: 2px;
}
.select2-container .select2-results__options {
  max-height: 238px !important;
}
.select2-container .select2-results__option {
  padding: 7px 15px !important;
  min-height: 34px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  font-size: calc(13px + var(--big-fs));
  line-height: 18px;
}
.select2-container .select2-results__option--highlighted {
  color: #000000 !important;
  background-color: rgba(214, 31, 121, 0.2) !important;
}
.select2-container .select2-results__option--selected {
  background-color: transparent !important;
  font-weight: bold;
}
.select2-container .select2-results__option--selected.select2-results__option--highlighted {
  background-color: rgba(214, 31, 121, 0.2) !important;
}
.select2-container .selection {
  display: flex;
  width: inherit;
  height: inherit;
  border-radius: 7px !important;
  overflow: hidden;
  border-color: #e0e0e0;
  box-sizing: border-box;
}
.select2-container--open {
  z-index: 100;
}
.select2-container--open .select2-selection {
  border-color: #d61f79 !important;
}
.select2-container--open .select2-selection__arrow {
  transform: rotate(180deg);
}
.select2-container--open.floated-label:before {
  color: #d61f79 !important;
}
.select2-container--open .sc-selection-label {
  color: #000000 !important;
}
.select2-container--disabled .select2-selection--single {
  border: none !important;
  background-color: rgba(212, 212, 212, 0.5) !important;
}
@media (max-width: 1024px) {
  .select2-container {
    display: flex;
    width: 100% !important;
    height: 42px !important;
    font-size: calc(14px + var(--big-fs));
    line-height: 18px;
  }
}

body > .select2-container {
  z-index: 1200;
  right: 0;
  width: auto !important;
  background-color: transparent !important;
}

span.select2-selection {
  outline: none;
}
